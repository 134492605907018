<template>
  <section v-if="loaded && gameCount">
    <transition name="fade">
      <div class="loading" v-if="!banner">
        <!--img src="@/assets/img/loading.gif" /-->
      </div>
    </transition>
    <!--button class="dropdown" :value="categoryName" @click="aselect=!aselect">{{ categoryName }}</button-->
    <div class="aselect">
      <ul :class="{'active': currentTab == ''}">
        <li><!-- @click="goPageByName('myinfo')" -->
          <div class="header">
            <h1 class="logo"><a @click="goPageByName('myinfo')"><img src="@/assets/img/boracaylogo.png" /></a></h1>
            <a v-if="userData" @click="goPageByName('msgMain')" class="mmsg"><img src="@/assets_mobile/img/message_off.png" /><span class="mgsn">{{thousand(unReadMessageCount ? unReadMessageCount.toString() : '0')}}</span></a>
            <ul class="minfo" v-if="userData">
              <li @click="goPageByName('myinfo')">
                <span>{{userData.memId}}</span>
              </li>
              <li @click="goPageByName('myinfo')">
                <em>₩</em>
                <span>{{userData.cashAmt ? thousand(userData.cashAmt.toString()): 0}}</span>
              </li>
              <li @click="goPageByName('myinfo')">
                <span>{{userData.memNick}}</span>
              </li>
              <li @click="goPageByName('point')">
                <em>P</em>
                <span>{{userData.pointAmt ? thousand(userData.pointAmt.toString()): 0}}</span>
              </li>
              <!--li class="myg"><img src="@/assets_mobile/img/mygicon.png" /><span>{{thousand(coinAmt ? coinAmt.toString(): '0')}}</span></li-->
            </ul>
            <div v-if="!userData">
              <a class="mloginbtn" @click="goPageByName('mobileJoin')">JOIN</a>
              <a class="mloginbtn on" @click="goPageByName('mobileLogin')">LOGIN</a>
            </div>
          </div>
        </li>
        <li
          @click="selectCategory($t('front.gameCategory.livecasino'), 'tab-2')"
          v-if="gameCount['casino']"
          :class="{'on': currentTab == 'tab-2', 'off': currentTab != 'tab-2'}"
          data-tab="tab-2">
          <a class="gamem">
            <span><img src="@/assets_mobile/img/casino-txt.svg" /></span>
            <img src="@/assets_mobile/img/m_casino.png" />
          </a>
          <a class="none">
            <em>CASINO</em>
            <img src="@/assets/img/boracaylogo.png" />
            <div>
              <div v-if="userData">
                <span>{{userData.memNick}}</span>
                <span class="won"><i>₩</i>{{userData.cashAmt ? thousand(userData.cashAmt.toString()): 0}}</span>
              </div>
            </div>
          </a>
        </li>
        <li
          @click="selectCategory($t('front.gnb.hotelcasino'), 'tab-3')"
          v-if="gameCount['hc-casino']"
          :class="{'on': currentTab == 'tab-3', 'off': currentTab != 'tab-3'}"
          data-tab="tab-3">
          <a class="gamem">
            <span><img class="h85" src="@/assets_mobile/img/hcasino-txt.svg" /></span>
            <img src="@/assets_mobile/img/m_sports.png" />
          </a>
          <a class="none">
            <em>HOTEL CASINO</em>
            <img src="@/assets/img/boracaylogo.png" />
            <div>
              <div v-if="userData">
                <span>{{userData.memNick}}</span>
                <span class="won"><i>₩</i>{{userData.cashAmt ? thousand(userData.cashAmt.toString()): 0}}</span>
              </div>
            </div>
          </a>
        </li>
        <li
          @click="selectCategory($t('front.gameCategory.slot'), 'tab-4')"
          v-if="gameCount['slot']"
          :class="{'on': currentTab == 'tab-4', 'off': currentTab != 'tab-4'}"
          data-tab="tab-4">
          <a class="gamem">
            <span><img src="@/assets_mobile/img/slot-txt.svg" /></span>
            <img src="@/assets_mobile/img/m_slot.png" />
          </a>
          <a class="none">
            <em>SLOT</em>
            <img src="@/assets/img/boracaylogo.png" />
            <div>
              <div v-if="userData">
                <span>{{userData.memNick}}</span>
                <span class="won"><i>₩</i>{{userData.cashAmt ? thousand(userData.cashAmt.toString()): 0}}</span>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>

    <div class="tab-content" v-bind:key="currentTab">
      <ul class="gamewrap">
        <template v-for="game in gameList" v-bind:key="game.code">
          <li v-if="currentTab === 'tab-1' || (currentTab !== 'tab-1' && currentTab === game.type)"
              :class="game.type">
            <img :src="getBackgroundImage(game)" />
            <p class="name">
              {{$i18n.locale === 'ko' ? game.codeName : game.codeNameEN}}
              <em :class="game.groupCode">
                <template v-if="game.groupCode === 'casino'">
                  <template v-if="game.groupCodeName.indexOf('HC-') === -1">
                    {{ $t('front.gnb.livecasino') }}
                  </template>
                  <template v-else>
                    {{ $t('front.gnb.hotelcasino') }}
                  </template>
                </template>
                <template v-if="game.groupCode === 'slot'">
                  <template v-if="game.groupCodeName.indexOf('H-') === -1">
                    {{ $t('front.gnb.slot') }}
                  </template>
                  <template v-else>
                    {{ $t('front.gnb.hotelslot') }}
                  </template>
                </template>
                <template v-if="game.groupCode === 'minigame'">
                  {{ $t('front.gnb.minigame') }}
                </template>
                <template v-if="game.groupCode === 'sport'">
                  {{ $t('front.gnb.sport') }}
                </template>
              </em>
            </p>
            <em class="glogo" :style="getBackgroundLogoImage(game)"></em>
            <template v-if="game.groupCode == 'slot'">
              <a @click="slotOpen('isSlot', 'slot', game.codeName, game.code)"></a>
            </template>

            <template v-else-if="game.groupCode == 'new'">
              <a @click="onCasinoSelectGame(game.groupCode, game.code)"></a>
            </template>

            <template v-else>
              <a @click="onCasinoSelectGame(game.groupCode, game.code)"></a>
            </template>

          </li>
        </template>
      </ul>
    </div>
    <a @click="goPageByNamey('mobileMain')" class="back"><img class="q" src="@/assets_mobile/img/back.png" /></a>
  </section>
  <template v-for="(item) in popupList" v-bind:key="item.bannerIdx">
    <div class="mainPop" v-if="item.delYn == 'N' && !item.isClose" :data-idx="item.bannerIdx">
      <img :src="item.bannerImg" />
      <div class="closeWrap">
        <a class="close" @click="popupCloseByCookie(item)">{{$t('front.main.today')}}</a>
        <a class="close" @click="popupClose(item)">{{$t('front.main.close')}}</a>
      </div>
    </div>
  </template>

</template>

<script>

import { getPopupCookie, setPopupCookie, tokenCheck } from '@/libs/auth-helper'
import { getSlotGame } from '@/api/game'
import { getMain, getMainCashStatus, getMainNotice } from '@/api/main'
import { isMobile } from '@/libs/utils'
import { mapState } from 'vuex'
import store from '@/store'

export default {
  name: 'MIndex',
  components: {
  },
  computed: {
    ...mapState([
      'userData',
      'commonCode',
      'commonCodeByOrder',
      'commonCodeByCode',
      'popup',
      'popupLogin',
      'banner',
      'gameCount'
    ])
  },
  data () {
    return {
      categoryName: this.$t('front.gameCategory.livecasino'),
      loaded: false,
      currentTab: '',
      gameList: [],
      cashInList: [],
      cashOutList: [],
      noticeList: [],
      popupList: {},
      listMaxCount: 5,
      noticeListMaxCount: 5
    }
  },
  watch: {
    async commonCodeByCode () {
      await this.gameLoad()
    },
    popup () {
      this.popupMerge()
    },
    popupLogin () {
      this.popupMerge()
    },
    async userData () {
      await store.dispatch('storePopup')
      if (this.userData) {
        await store.dispatch('storePopupLogin')
      }
      await this.popupMerge()
    }
  },
  methods: {
    selectCategory (text, tab) {
      if (this.currentTab !== tab) {
        this.currentTab = tab
      } else {
        this.currentTab = ''
      }
      this.categoryName = text
    },
    loadCashStatus () {
      getMainCashStatus({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.data)
          this.cashInList = result.data.cashInList.slice(0, this.listMaxCount)
          this.cashOutList = result.data.cashOutList.slice(0, this.listMaxCount)
        }
      })
    },
    loadMain () {
      if (this.userData) {
        getMain({}).then(res => {
          // console.log(res)
        })
      }
    },
    getBackgroundImage (item) {
      try {
        const image = require('../assets_mobile/img/game_main_pc_' + item.code + '_off.png')
        if (image) {
          return image
        } else {
          return ''
        }
      } catch (e) {
        return ''
      }
    },
    getBackgroundLogoImage (item) {
      try {
        const image = require('../assets_mobile/img/glogo' + item.code + '.png')
        return { backgroundImage: 'url(' + image + ')' }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    loadNotice () {
      getMainNotice({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.noticeList = result.data.boardList
        }
      })
    },
    slotOpen (event, groupCode, codeName) {
      console.log(event, groupCode, codeName)
      this.emitter.emit(event, { groupCode, codeName })
    },
    popupMerge () {
      const popupLogin = this.userData ? this.popupLogin : []
      const pList = this.popup.concat(popupLogin)

      this.popupList = {}

      pList.forEach(item => {
        if (!item.isClose) {
          const key = item.bannerIdx
          const siteId = process.env.VUE_APP_SITE_ID || 'moka'
          const name = siteId + '_popup_' + item.bannerIdx
          const cookie = getPopupCookie(name)
          if (cookie) {
            item.isClose = true
          } else {
            item.isClose = false
          }
          if (!this.popupList[key]) {
            this.popupList[key] = item
          }
        }
      })
      console.log(this.popupList)
    },
    popupClose (item) {
      item.isClose = true
    },
    popupCloseByCookie (item) {
      item.isClose = true
      setPopupCookie(item)
    },
    gameLoad () {
      console.log(this.commonCodeByCode)
      const gameList = JSON.parse(JSON.stringify(this.commonCodeByCode))

      for (const item in gameList) {
        const game = gameList[item]
        let type = 'type2'
        if (game.groupCode === 'casino') {
          type = 'tab-2'
          if (game.groupCodeName.indexOf('HC-') > -1) {
            type = 'tab-3'
          }
        } else if (game.groupCode === 'slot') {
          type = 'tab-4'
          if (game.groupCodeName.indexOf('H-') > -1) {
            type = 'tab-7'
          }
        } else if (game.groupCode === 'minigame') {
          type = 'tab-6'
        } else if (game.groupCode === 'sport') {
          type = 'tab-5'
        }
        game.type = type
        this.gameList.push(game)
      }

      this.loaded = true

      if (this.$route.params.prodId && this.$route.params.type) {
        if (this.userData && this.userData.kplayId) {
          const params = {
            kplayId: this.userData.kplayId,
            gameCode: this.$route.params.prodId,
            gameGroupCode: 'slot',
            type: this.$route.params.type,
            prodId: this.$route.params.prodId
          }
          getSlotGame(params)
            .then(response => {
              const result = response.data
              if (result.resultCode === '0') {
                const data = result.data
                const gameUrl = data.gameurl

                const _width = 1100
                const _height = 680
                const _left = Math.ceil((window.screen.width - _width) / 2)
                const _top = 100
                if (isMobile()) {
                  // mobile
                  const p = window.open('/', '_blank')
                  p.location.href = gameUrl
                } else {
                  // pc
                  window.open(gameUrl, 'evolution', `width=${_width},height=${_height},left=${_left},top=${_top}`)
                }
              }
            })
        } else {
          this.onCheck('front.error.afterSignin')
        }
      }
    },
    goPageByNamey (name, params) {
      const query = { name: name }
      if (params) {
        query.params = params
      }
      this.$router.go(this.$router.currentRouter)
    }
  },
  async created () {
    await this.loadMain()
    await this.loadNotice()
    await this.loadCashStatus()
    this.gameLoad()
  },
  async beforeRouteEnter (to, from, next) {
    await tokenCheck()
    next()
  }
}
</script>
<style scoped>
.loading {z-index: 10;position: relative;width: 100%;height: 78px;display:flex;align-items: center;justify-content: center;}
.loading img {height: 100%;}
.mainPop {position: relative;min-width: 320px;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);background: #fff;border: 1px solid #1c594e;z-index: 100;}
.mainPop .closeWrap {width: calc(100% - 30px);display: flex;align-items: center;justify-content: end;gap: 15px;padding: 15px;background: rgba(0,0,0,.7);color: #fff;font-size: 12px;}
.mainPop a.close {color: #fff;}
.mainPop>img {width: 320px;height: 440px;vertical-align: bottom;object-fit: cover;object-position: center;}
</style>
<style>
.mainSlide img{width:100%;height: 200px;object-fit: cover;object-position: center;}
.swiper-button-next {background-image: url('~@/assets/img/slideright.png') !important;background-size: 11px 20px !important;}
.swiper-button-prev {background-image: url('~@/assets/img/slideleft.png') !important;background-size: 11px 20px !important;}
</style>
<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/main.css"></style>
<style scoped src="@/styles_mobile/swiper.min.css"></style>
